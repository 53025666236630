const url = '/api/v2/sales-channels/'


export const SalesApiUrls = {
    // каналы продаж go2trip
    async getSales(params) {
        return (await axios.get(url, { params: params })).data;
    },
    async createSale(body) {
        return (await axios.post(url, body)).data;
    },
    async getSale(id) {
        return (await axios.get(`${url}${id}/`)).data;
    },
    async setSale(id, body) {
        return (await axios.patch(`${url}${id}/`, body)).data;
    },
    async deleteSale(id) {
        return (await axios.delete(`${url}${id}/`)).data;
    },

    // добавить блоки 
    async createBlock(body){
        return (await axios.post(url+'add-blocks/', body)).data;
    },
    // взять туроператоры
    async getOperators(params) {
        return (await axios.get(url+'tour-operators/', { params: params })).data;
    },
};
  
