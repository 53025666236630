<template>
  <v-container fluid tag="section">
    <v-card class="px-5 py-3">
      <v-tabs v-model="tabValue" class="mb-3">
        <v-tab @click="deleted = false">Активные</v-tab>
        <v-tab @click="deleted = true">Не активные</v-tab>
      </v-tabs>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        item-key="name"
        :server-items-length="roomLength"
        :items-per-page="page_size"
        @update:options="updatePagination"
        :footer-props="{ 'items-per-page-options': items_per_page }"
      >
        <template v-slot:[`item.commission`]="{ item }"
          >{{ item.commission }}%</template
        >
        <template v-slot:[`item.dateBeg`]="{ item }">
          <template v-if="item.has_blocks">
            {{ getStart(item) }}
          </template>
          <span v-if="!item.has_blocks">Блоки еще не присвоены</span>
        </template>
        <template v-slot:[`item.has_blocks`]="{ item }">
          <span v-if="item.has_blocks">Да</span>
          <span v-if="!item.has_blocks">Нет</span>
        </template>
        <template v-slot:[`item.is_open`]="{ item }">
          <v-btn
            text
            small
            :color="!item.is_open ? 'primary' : 'error'"
            @click="changePerm(item)"
            :disabled="disabledBtn"
          >
            <span v-if="!item.is_open">Открыть</span>
            <span v-else>Закрыть</span>
          </v-btn>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            class="px-1"
            color="red"
            v-if="deleted == '0'"
            small
            @click="remove(item)"
          >
            mdi-delete
          </v-icon>
          <v-icon
            class="px-1"
            v-if="deleted == '0' && getIsAdmin()"
            small
            @click="edit(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            class="px-1"
            v-if="deleted == '0' && getIsAdmin()"
            small
            @click="settings(item)"
          >
            mdi-cog
          </v-icon>
          <v-icon
            class="px-1"
            v-if="deleted == '0' && getIsAdmin()"
            small
            @click="addBlock(item)"
          >
            mdi-plus-box
          </v-icon>
          <v-btn
            text
            small
            v-if="deleted != '0'"
            color="primary"
            @click="remove(item)"
          >
            <span> Активировать </span>
          </v-btn>
        </template>
      </v-data-table>
      <v-row v-if="getIsAdmin()">
        <v-col>
          <v-btn color="primary" to="/create"> Создать </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import toast from "../../../plugins/toast";
import moment from "moment";
import { SalesApiUrls } from "@/services/accommodationRequests/sales.api.js";
export default {
  name: "SalesChannels",
  data() {
    return {
      disabledBtn: false,
      loading: false,
      items_per_page: [10, 20, 50],
      items: [],
      page_size: 10,
      roomLength: 0,
      tabValue: 0,
      options: {},
      deleted: false,
      page: 1,
      headers: [
        {
          text: "Название источника",
          align: "start",
          sortable: false,
          value: "title",
          class: "table-title"
        },
        { text: "Город", value: "city", class: "table-title" },
        { text: "Дата начала работ", value: "dateBeg", class: "table-title" },
        { text: "Размер комиссии", value: "commission", class: "table-title" },
        {
          text: "Доступ в свободную продажу",
          value: "is_open",
          class: "table-title"
        },
        {
          value: "actions",
          class: "table-title"
        }
      ]
    };
  },
  watch: {
    deleted() {
      this.loadSales();
    }
  },
  methods: {
    addBlock(item) {
      this.$router.push(`/add-blocks?id=${item.id}`);
    },
    settings(item) {
      this.$router.push(`/settings?id=${item.id}`);
    },
    edit(item) {
      this.$router.push(`/create?id=${item.id}`);
    },
    async loadSales(pag = {}) {
      this.loading = true;
      let params = {
        "page[number]": pag.page || null,
        "page[size]": pag.itemsPerPage || null,
        deleted: this.deleted
      };
      let res = await SalesApiUrls.getSales(params);
      this.options = res.meta.pagination;
      this.items = res.results;
      this.roomLength = res.meta.pagination.count;
      this.loading = false;
    },
    async remove(item) {
      this.loading = true;
      this.options.page = 1;
      try {
        await SalesApiUrls.deleteSale(item.id);
        toast.success("Успешно удалено!");
        this.loadSales();
      } finally {
        this.loading = false;
      }
    },
    updatePagination(pag) {
      this.loadSales(pag);
    },
    getIsAdmin() {
      return localStorage.getItem("is_admin") === "true";
    },
    getStart(item) {
      return moment(item.start_date * 1000).format("DD.MM.YYYY");
    },
    async changePerm(item) {
      this.disabledBtn = true;
      try {
        await SalesApiUrls.setSale(item.id, { is_open: !item.is_open });
        toast.success("Успешно изменилось!");
        this.loadSales();
      } finally {
        this.disabledBtn = false;
      }
    }
  }
};
</script>
